import React, { useCallback, useEffect, useRef, useState } from 'react';
import Input from 'src/components/Input/Input';
import { useAddPopup, useRemovePopup } from 'src/state/application/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import ToolBar from 'src/components/ToolBar/ToolBar';
import {
  HtmlColumnsType,
  HtmlSimpleParameterType,
  HtmlTableParameterType,
  EmailTemplateType,
  EnumAction,
  EnumHtmlTemplateDataType,
  EventButton,
  ImageType,
} from 'src/api/models';
import { uniqueId } from 'lodash';
import MonacoEditor from '@monaco-editor/react';
import { Editor } from '@tinymce/tinymce-react';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import { LEFT_MOUSE_BUTTON } from 'src/common/constant/Constant';
import Tabs from 'src/components/Tabs';
import { BASE_SETTING_URL } from 'src/constants';
import {
  useDeleteEmailTemplateImage,
  useGetEmailTemplateById,
  useGetEmailTemplateImage,
  usePostEmailTemplate,
  usePostEmailTemplateImage,
  usePutEmailTemplate,
} from 'src/api/emailTemplateApi';
import './EmailTemplateDetail.css';

enum TabKey {
  Content,
  SourceCode,
}

const EmailTemplateDetail: React.FC = () => {
  //Function
  const getEmailTemplateById = useGetEmailTemplateById();
  const postEmailTemplate = usePostEmailTemplate();
  const putEmailTemplate = usePutEmailTemplate();
  const addPopup = useAddPopup();
  const removePopup = useRemovePopup();

  //Value
  const navigate = useNavigate();
  const tinyEditorRef = useRef(null);

  //State
  const [emailTemplateKey, setEmailTemplateKey] = useState<string>('');
  const [emailTemplateTitle, setEmailTemplateTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [displayOrder, setDisplayOrder] = useState<number>(null);
  //State
  const [images, setImages] = useState<ImageType[] | null>([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [maxImageIndex, setMaxImageIndex] = useState(0);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [tab, setTab] = useState(TabKey.Content);
  //Param state
  const [emailSimpleParameter, setEmailSimpleParameter] =
    useState<HtmlSimpleParameterType[]>(null);
  const [emailTableParameter, setEmailTableParameter] =
    useState<HtmlTableParameterType[]>(null);

  //Function
  const [isShowFullSizeImg2, setShowFullSizeImg2] = useState(false);
  const getEmailTemplateImage = useGetEmailTemplateImage();
  const addEmailTemplateImage = usePostEmailTemplateImage();
  const deleteEmailTemplateImage = useDeleteEmailTemplateImage();
  const editorRef = useRef(null);

  //Value
  const params = useParams<{ type: string; emailTemplateId: string }>();
  const emailTemplateId = Number(params.emailTemplateId);
  const isAdd = Number(params.type) === EnumViewType.Edit && emailTemplateId == 0;
  const isDisable = Number(params.type) == EnumViewType.View;

  useEffect(() => {
    if (!isAdd) {
      getEmailTemplateById(emailTemplateId)
        .then((data) => {
          setEmailTemplateKey(data.emailTemplateKey);
          setEmailTemplateTitle(data.emailTemplateTitle);
          setValue(data.emailTemplateValue);
          setDescription(data.description);
          setDisplayOrder(data.displayOrder);
          setEmailSimpleParameter(data.emailSimpleParameter);
          setEmailTableParameter(data.emailTableParameter);
        })
        .catch((error) => {
          isAdd
            ? null
            : addPopup({
                error: {
                  message: error.errorMessage,
                  title: 'Đã có lỗi xảy ra!',
                },
              });
        });
    }
    getEmailTemplateImage()
      .then((data) => {
        setImages(data);
        setMaxImageIndex(data.length - 1);
      })
      .catch((error) => {
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
  }, [
    addPopup,
    isAdd,
    reloadFlag,
    emailTemplateId,
    getEmailTemplateById,
    getEmailTemplateImage,
  ]);

  //Function Content

  function handleEditorDidMount(editor: unknown) {
    editorRef.current = editor;
  }

  const onChangeSimpleParam = (value: string, index: number, type: string) => {
    if (emailSimpleParameter) {
      const temp = emailSimpleParameter.filter(() => true);
      if (type == 'PARAMETERNAME') {
        temp[index].parameterName = value;
      } else if (type == 'DESCRIPTION') {
        temp[index].description = value;
      } else if (type == 'DATATYPE') {
        temp[index].dataType = Number(value);
      }
      setEmailSimpleParameter(temp);
    }
  };

  const onChangeTableParam = (
    value: string,
    type: string,
    mainIndex: number,
    columnIndex?: number,
  ) => {
    if (emailTableParameter) {
      const temp = emailTableParameter.filter(() => true);
      if (type == 'TABLENAME') {
        temp[mainIndex].tableName = value;
      } else if (type == 'COLUMNTITLE') {
        temp[mainIndex].columns[columnIndex].columnTitle = value;
      } else if (type == 'COLUMNNAME') {
        temp[mainIndex].columns[columnIndex].columnName = value;
      } else if (type == 'DATATYPE') {
        temp[mainIndex].columns[columnIndex].dataType = Number(value);
      } else if (type == 'COLUMNCSS') {
        temp[mainIndex].columns[columnIndex].columnCss = value;
      } else if (type == 'TABLECSS') {
        temp[mainIndex].tableCss = value;
      } else if (type == 'ROWCSS') {
        temp[mainIndex].rowCss = value;
      }
      setEmailTableParameter(temp);
    }
  };

  const onDeleteColumn = (mainIndex: number, columnIndex: number) => {
    if (emailTableParameter) {
      const temp = emailTableParameter.filter(() => true);
      if (temp[mainIndex].columns.length >= 2) {
        temp[mainIndex].columns.splice(columnIndex, 1);
        setEmailTableParameter(temp);
      } else {
        temp.splice(mainIndex, 1);
        setEmailTableParameter(temp);
      }
    }
  };

  const onAddColumn = (mainIndex: number) => {
    const arr = emailTableParameter.filter(() => true);
    const temp: HtmlColumnsType = {
      columnTitle: '',
      columnName: '',
      dataType: EnumHtmlTemplateDataType.Text,
      columnCss: '',
    };
    arr[mainIndex].columns.push(temp);
    setEmailTableParameter(arr);
  };

  const onDeleteSimpleParam = (index: number) => {
    if (emailSimpleParameter) {
      const temp = emailSimpleParameter.filter(() => true);
      temp.splice(index, 1);
      setEmailSimpleParameter(temp);
    }
  };

  const onAddSimpleParam = () => {
    if (emailSimpleParameter) {
      const arr = emailSimpleParameter.filter(() => true);
      const temp: HtmlSimpleParameterType = {
        parameterName: '',
        description: '',
        dataType: EnumHtmlTemplateDataType.Text,
      };
      arr.push(temp);
      setEmailSimpleParameter(arr);
    } else {
      const arr: HtmlSimpleParameterType[] = [
        {
          parameterName: '',
          description: '',
          dataType: EnumHtmlTemplateDataType.Text,
        },
      ];
      setEmailSimpleParameter(arr);
    }
  };

  const onAddTableParam = () => {
    if (emailTableParameter) {
      const arr = emailTableParameter.filter(() => true);
      const temp: HtmlTableParameterType = {
        tableName: '',
        columns: [
          {
            columnTitle: '',
            columnName: '',
            dataType: EnumHtmlTemplateDataType.Text,
            columnCss: '',
          },
        ],
        tableCss: '',
        rowCss: '',
      };
      arr.push(temp);
      setEmailTableParameter(arr);
    } else {
      const arr: HtmlTableParameterType[] = [
        {
          tableName: '',
          columns: [
            {
              columnTitle: '',
              columnName: '',
              dataType: EnumHtmlTemplateDataType.Text,
              columnCss: '',
            },
          ],
          tableCss: '',
          rowCss: '',
        },
      ];
      setEmailTableParameter(arr);
    }
  };

  const onAddEmailTemPlate = useCallback(() => {
    const pageValue =
      tab == TabKey.SourceCode
        ? editorRef.current.getValue()
        : tinyEditorRef.current.getContent();

    const emailTemplate: EmailTemplateType = {
      displayOrder: displayOrder,
      emailTemplateKey: emailTemplateKey,
      emailTemplateTitle: emailTemplateTitle,
      emailTemplateValue: pageValue,
      emailSimpleParameter: emailSimpleParameter,
      emailTableParameter: emailTableParameter,
      description: description,
    };
    postEmailTemplate(emailTemplate)
      .then((r) => {
        addPopup({
          txn: {
            success: true,
            summary: 'Thêm thành công!',
          },
        });
        setValue(r.emailTemplateValue);
      })
      .catch((error) => {
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
  }, [
    tab,
    displayOrder,
    emailTemplateKey,
    emailTemplateTitle,
    emailSimpleParameter,
    emailTableParameter,
    description,
    postEmailTemplate,
    removePopup,
    addPopup,
  ]);

  const onEditEmailTemPlate = useCallback(() => {
    const pageValue =
      tab == TabKey.SourceCode
        ? editorRef.current.getValue()
        : tinyEditorRef.current.getContent();

    const emailTemplate: EmailTemplateType = {
      displayOrder: displayOrder,
      emailTemplateId: emailTemplateId,
      emailTemplateKey: emailTemplateKey,
      emailTemplateTitle: emailTemplateTitle,
      emailTemplateValue: pageValue,
      emailSimpleParameter: emailSimpleParameter,
      emailTableParameter: emailTableParameter,
      description: description,
    };
    putEmailTemplate(emailTemplate)
      .then((r) => {
        console.log('=======r=====');
        console.log(r);
        addPopup({
          txn: {
            success: true,
            summary: 'Sửa thành công',
          },
        });
        setValue(r.emailTemplateValue);
      })
      .catch((error) => {
        console.log('=======errr=====');
        console.log(error);
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
  }, [
    tab,
    displayOrder,
    emailTemplateId,
    emailTemplateKey,
    emailTemplateTitle,
    emailSimpleParameter,
    emailTableParameter,
    description,
    putEmailTemplate,
    removePopup,
    addPopup,
  ]);
  //End of function

  //Function Image
  const onAddImage = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.size <= 55242880) {
        const formData = new FormData();
        formData.append('name', '');
        formData.append('file', event.target.files[0]);

        addEmailTemplateImage(formData)
          .then(() => {
            addPopup({
              txn: {
                success: true,
                summary: 'Upload ảnh thành công!',
              },
            });
            setReloadFlag(!reloadFlag);
          })
          .catch((error) => {
            addPopup({
              error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
            });
          });
        addPopup({
          txn: {
            success: true,
            summary: 'Vui lòng chờ upload ảnh!',
          },
        });
      } else {
        addPopup({
          error: { message: 'Ảnh tối đa 5MB', title: 'Đã có lỗi xảy ra!' },
        });
      }
    }
  };

  const onShowFullSize = (name: string, isShow: boolean) => {
    if (name == 'img2') setShowFullSizeImg2(isShow);
  };

  const onDeleteEmailTemplateImage = () => {
    const onCancel = () => {
      console.log('cancel');
    };

    const onConfirm = () => {
      deleteEmailTemplateImage(images[imageIndex].fileId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa ảnh thành công!',
            },
          });
          setImageIndex(0);
          setMaxImageIndex(maxImageIndex - 1);
          setReloadFlag(!reloadFlag);
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
          setImageIndex(0);
          setReloadFlag(!reloadFlag);
        });
      addPopup({
        txn: {
          success: true,
          summary: 'Đợi một chút!',
        },
      });
    };
    const listToDo: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        action: onCancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    // addPopup(
    //   {
    //     confirm: {
    //       width: '25vw',
    //       height: '20vh',
    //       question: 'Bạn có chắc muốn xóa ảnh này?',
    //       listActionButton: listToDo,
    //     },
    //   },
    //   'delete-image',
    // );
  };

  const onBackToEmailTemplate = (e: MouseEvent) => {
    if (e.button === LEFT_MOUSE_BUTTON) {
      navigate(-1);
    }
  };

  const onChangeEditMode = (e: MouseEvent) => {
    if (e.button === LEFT_MOUSE_BUTTON) {
      navigate(`${BASE_SETTING_URL}/email-template/${EnumViewType.Edit}/id/${emailTemplateId}`);
    }
  };

  const onPreview = (e: MouseEvent) => {
    if (e.button === LEFT_MOUSE_BUTTON) {
      addPopup({
        // view: {
        //   width: '85vw',
        //   height: '90vh',
        //   title: 'Xem trước',
        //   isManualRemove: true,
        //   data: (
        //     <StaticPagePreview
        //       key={uniqueId()}
        //       value={
        //         tab == TabKey.SourceCode
        //           ? editorRef?.current?.getValue()
        //           : tinyEditorRef?.current?.getContent()
        //       }
        //     />
        //   ),
        //   isContext: false,
        // },
      });
    }
  };
  //End of function

  const listEditToolButton: EventButton[] = [
    {
      name: 'Trở về',
      icon: 'arrow_back',
      actionType: EnumAction.View,
      buttonClass: 'info100 static-page-tool-btn',
      action: onBackToEmailTemplate,
      align: 'center',
    },
    {
      name: 'Lưu',
      icon: 'add',
      actionType: isAdd ? EnumAction.Add : EnumAction.Edit,
      buttonClass: 'info static-page-tool-btn',
      action: isAdd ? onAddEmailTemPlate : onEditEmailTemPlate,
      align: 'center',
    },
    {
      name: 'Thêm ảnh',
      icon: 'cloud_upload',
      actionType: EnumAction.Add,
      buttonClass: 'info700 static-page-tool-btn cloud_upload-btn',
      action: onAddImage,
      align: 'center',
      file: true,
    },
    {
      name: 'Xem trước',
      icon: 'preview',
      actionType: EnumAction.View,
      buttonClass: 'info600 static-page-tool-btn cloud_upload-btn',
      action: onPreview,
      align: 'center',
    },
  ];

  const listViewToolButton: EventButton[] = [
    {
      name: 'Trở về',
      icon: 'arrow_back',
      actionType: EnumAction.View,
      buttonClass: 'info100 static-page-tool-btn',
      action: onBackToEmailTemplate,
      align: 'center',
    },
    {
      name: 'Sửa',
      icon: 'edit',
      actionType: EnumAction.Edit,
      buttonClass: 'info100 static-page-tool-btn',
      action: onChangeEditMode,
      align: 'center',
    },
    {
      name: 'Xem trước',
      icon: 'preview',
      actionType: EnumAction.View,
      buttonClass: 'info100 static-page-tool-btn cloud_upload-btn',
      action: onPreview,
      align: 'center',
    },
  ];

  const tabs = [
    {
      title: 'Content',
      key: TabKey.Content,
      onClick: () => {
        if (tab == TabKey.SourceCode) {
          const content = editorRef.current.getValue();
          setValue(content);
        }
        setTab(TabKey.Content);
      },
    },
    {
      title: 'Source code',
      key: TabKey.SourceCode,
      onClick: () => {
        if (tab == TabKey.Content) {
          const content = tinyEditorRef.current.getContent();
          setValue(content);
        }
        setTab(TabKey.SourceCode);
      },
    },
  ];

  //Component
  const paramComponent = () => {
    return (
      <div className="email-template-detail-param-container">
        <div className="table-param-container">
          <div className="table-param">
            <div className="table-param-component title">tableName</div>
            <div className="table-param-component title">tableCss</div>
            <div className="table-param-component title">rowCss</div>
            <div className="table-param-detail">
              <div className="table-param-detail-component">
                <div className="table-param-component title">columnTitle</div>
                <div className="table-param-component title">columnName</div>
                <div className="table-param-component title">dataType</div>
                <div className="table-param-component title">columnCss</div>
                <div className="email-template-btn-component title"></div>
              </div>
            </div>
          </div>
          {emailTableParameter
            ? emailTableParameter.map((value: HtmlTableParameterType, mainIndex: number) => {
                return (
                  <div className={`table-param ${mainIndex % 2 == 0 ? 'chan' : 'le'}`}>
                    <div className="table-param-component">
                      <input
                        type="text"
                        value={value.tableName}
                        onChange={(event) => {
                          onChangeTableParam(event.target.value, 'TABLENAME', mainIndex);
                        }}
                      />
                    </div>
                    <div className="table-param-component">
                      <input
                        type="text"
                        value={value.tableCss}
                        onChange={(event) => {
                          onChangeTableParam(event.target.value, 'TABLECSS', mainIndex);
                        }}
                      />
                    </div>
                    <div className="table-param-component">
                      <input
                        type="text"
                        value={value.rowCss}
                        onChange={(event) => {
                          onChangeTableParam(event.target.value, 'ROWCSS', mainIndex);
                        }}
                      />
                    </div>
                    <div className="table-param-detail">
                      {value.columns.map((v: HtmlColumnsType, columnIndex: number) => {
                        return (
                          <div className="table-param-detail-component">
                            <div className="table-param-component">
                              <input
                                type="text"
                                value={v.columnTitle}
                                onChange={(event) => {
                                  onChangeTableParam(
                                    event.target.value,
                                    'COLUMNTITLE',
                                    mainIndex,
                                    columnIndex,
                                  );
                                }}
                              />
                            </div>
                            <div className="table-param-component">
                              <input
                                type="text"
                                value={v.columnName}
                                onChange={(event) => {
                                  onChangeTableParam(
                                    event.target.value,
                                    'COLUMNNAME',
                                    mainIndex,
                                    columnIndex,
                                  );
                                }}
                              />
                            </div>
                            <div className="table-param-component">
                              <select
                                value={v.dataType}
                                onChange={(event) => {
                                  onChangeTableParam(
                                    event.target.value,
                                    'DATATYPE',
                                    mainIndex,
                                    columnIndex,
                                  );
                                }}
                              >
                                <option value={EnumHtmlTemplateDataType.Text}>Text</option>
                                <option value={EnumHtmlTemplateDataType.Int}>Int</option>
                                <option value={EnumHtmlTemplateDataType.Date}>Date</option>
                                <option value={EnumHtmlTemplateDataType.PhoneNumber}>
                                  PhoneNumber
                                </option>
                                <option value={EnumHtmlTemplateDataType.Email}>Email</option>
                                <option value={EnumHtmlTemplateDataType.Boolean}>
                                  Boolean
                                </option>
                                <option value={EnumHtmlTemplateDataType.Percentage}>
                                  Percentage
                                </option>
                                <option value={EnumHtmlTemplateDataType.BigInt}>BigInt</option>
                                <option value={EnumHtmlTemplateDataType.Decimal}>
                                  Decimal
                                </option>
                                <option value={EnumHtmlTemplateDataType.Month}>Month</option>
                                <option value={EnumHtmlTemplateDataType.QuarterOfYear}>
                                  QuarterOfYear
                                </option>
                                <option value={EnumHtmlTemplateDataType.Year}>Year</option>
                                <option value={EnumHtmlTemplateDataType.DateRange}>
                                  DateRange
                                </option>
                              </select>
                            </div>
                            <div className="table-param-component">
                              <input
                                type="text"
                                value={v.columnCss}
                                onChange={(event) => {
                                  onChangeTableParam(
                                    event.target.value,
                                    'COLUMNCSS',
                                    mainIndex,
                                    columnIndex,
                                  );
                                }}
                              />
                            </div>
                            <div
                              className="email-template-btn-component title"
                              onClick={() => {
                                onDeleteColumn(mainIndex, columnIndex);
                              }}
                            >
                              <i className="fas fa-trash"></i>
                            </div>
                          </div>
                        );
                      })}
                      <div className="table-param-detail-component">
                        <div
                          className="email-template-add-btn"
                          onClick={() => {
                            onAddColumn(mainIndex);
                          }}
                        >
                          Add column
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
          <div className="table-param">
            <div className="email-template-add-btn" onClick={onAddTableParam}>
              Add table param
            </div>
          </div>
        </div>
        <div className="simple-param-container">
          <div className="simple-param">
            <div className="simple-param-component title">parameterName</div>
            <div className="simple-param-component title">description</div>
            <div className="simple-param-component title">dataType</div>
            <div className="email-template-btn-component title"></div>
          </div>
          {emailSimpleParameter
            ? emailSimpleParameter.map((value: HtmlSimpleParameterType, index: number) => {
                return (
                  <div className="simple-param">
                    <div className="simple-param-component">
                      <input
                        type="text"
                        value={value.parameterName}
                        onChange={(event) => {
                          onChangeSimpleParam(event.target.value, index, 'PARAMETERNAME');
                        }}
                      />
                    </div>
                    <div className="simple-param-component">
                      <input
                        type="text"
                        value={value.description}
                        onChange={(event) => {
                          onChangeSimpleParam(event.target.value, index, 'DESCRIPTION');
                        }}
                      />
                    </div>
                    <div className="simple-param-component">
                      <select
                        value={value.dataType}
                        onChange={(event) => {
                          onChangeSimpleParam(event.target.value, index, 'DATATYPE');
                        }}
                      >
                        <option value={EnumHtmlTemplateDataType.Text}>Text</option>
                        <option value={EnumHtmlTemplateDataType.Int}>Int</option>
                        <option value={EnumHtmlTemplateDataType.Date}>Date</option>
                        <option value={EnumHtmlTemplateDataType.PhoneNumber}>
                          PhoneNumber
                        </option>
                        <option value={EnumHtmlTemplateDataType.Email}>Email</option>
                        <option value={EnumHtmlTemplateDataType.Boolean}>Boolean</option>
                        <option value={EnumHtmlTemplateDataType.Percentage}>Percentage</option>
                        <option value={EnumHtmlTemplateDataType.BigInt}>BigInt</option>
                        <option value={EnumHtmlTemplateDataType.Decimal}>Decimal</option>
                        <option value={EnumHtmlTemplateDataType.Month}>Month</option>
                        <option value={EnumHtmlTemplateDataType.QuarterOfYear}>
                          QuarterOfYear
                        </option>
                        <option value={EnumHtmlTemplateDataType.Year}>Year</option>
                        <option value={EnumHtmlTemplateDataType.DateRange}>DateRange</option>
                      </select>
                    </div>
                    <div
                      className="email-template-btn-component title"
                      onClick={() => {
                        onDeleteSimpleParam(index);
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </div>
                  </div>
                );
              })
            : null}
          <div className="simple-param">
            <div className="email-template-add-btn" onClick={onAddSimpleParam}>
              Add simple param
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      {/* <ToolBar
        toolbarName={'Trang thông tin'}
        listRightButton={isDisable ? listViewToolButton : listEditToolButton}
        width={'100%'}
        height={'50px'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
      /> */}
      <div className="row">
        <div className="col-12 static-page-image">
          <div className="container-fluid">
            <div className="row justify-content-center">
              {images.map((img: ImageType, index: number) => {
                return (
                  <div
                    key={uniqueId()}
                    className={`col-1 p-0 m-1 static-page-thumb-img-child ${
                      imageIndex === index ? 'focus-img' : ''
                    }`}
                    onClick={() => {
                      setImageIndex(index);
                    }}
                  >
                    <img className="img-item" src={img.thumbUrl} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {paramComponent()}
        <div className="col-12 add-static-page-view">
          <div className="row">
            <div className="col-9 content">
              <div className="value-content">
                <div className="row content-row">
                  <div className="col-3">
                    <div className="add-static-page-child">
                      <span className="mr-2">
                        Key:<span style={{ color: 'red' }}>*</span>
                      </span>
                      <Input
                        disabled={isDisable}
                        value={emailTemplateKey}
                        onChange={setEmailTemplateKey}
                      />
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="add-static-page-child">
                      <span className="mr-2">
                        Tiêu đề:<span style={{ color: 'red' }}>*</span>
                      </span>
                      <Input
                        disabled={isDisable}
                        value={emailTemplateTitle}
                        onChange={setEmailTemplateTitle}
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="add-static-page-child">
                      <span className="mr-2">
                        Thứ tự:<span style={{ color: 'red' }}>*</span>
                      </span>
                      <Input
                        disabled={isDisable}
                        value={displayOrder}
                        onChange={setDisplayOrder}
                      />
                    </div>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-12">
                    <div className="add-static-page-child">
                      <span className="mr-2">Mô tả:</span>
                      <Input
                        disabled={isDisable}
                        value={description}
                        onChange={setDescription}
                      />
                    </div>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-12">
                    <div className="add-static-page-child">
                      <span className="mr-2">
                        Nội dung:<span style={{ color: 'red' }}>*</span>
                      </span>

                      <Tabs activeTab={tab} tabs={tabs} />
                      <div className="tabs-content">
                        {tab == TabKey.SourceCode ? (
                          <MonacoEditor
                            height="90vh"
                            defaultLanguage="html"
                            value={value}
                            onMount={handleEditorDidMount}
                            options={{
                              readOnly: isDisable,
                            }}
                          />
                        ) : (
                          <Editor
                            disabled={isDisable}
                            apiKey={'9rjkn0nooxthjws4ylk4s6ogwe2x1ll74eozkdq1or2maa59'}
                            onInit={(evt, editor) => (tinyEditorRef.current = editor)}
                            initialValue={value}
                            init={{
                              height: '90vh',
                              menubar: true,
                              plugins: [
                                'link image code textcolor charmap preview anchor searchreplace visualblocks fullscreen insertdatetime media table code wordcount help align',
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount',
                              ],
                              toolbar:
                                'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'myButton | undo | redo | link | image | code | forecolor| backcolor |charmap |align |print | preview | anchor | view |searchreplace |visualblocks |fullscreen| insertdatetime |media |table |paste |help |wordcount' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                              content_style:
                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 content">
              <div className="static-page-fullsize-img">
                {images.length > 0 ? (
                  <div
                    className="btn-nav-static-page-image back"
                    onClick={() => {
                      setImageIndex(imageIndex - 1 >= 0 ? imageIndex - 1 : maxImageIndex);
                    }}
                  >
                    <span className="material-icons">arrow_back_ios_new</span>
                  </div>
                ) : null}
                <div
                  className={`static-page-img-child center ${
                    isShowFullSizeImg2 ? 'full-size' : ''
                  }`}
                >
                  {images.length > 0 ? (
                    <div
                      className="btn-delete-static-page-image"
                      title="Xóa"
                      onClick={() => {
                        onDeleteEmailTemplateImage();
                      }}
                    >
                      <span className="material-icons">delete</span>
                    </div>
                  ) : null}
                  {images.length > 0 ? (
                    <div>
                      <div
                        className="btn-zoom-out-static-page-image"
                        title="Thu nhỏ"
                        onClick={() => {
                          onShowFullSize('img2', false);
                        }}
                      >
                        <span className="material-icons">close</span>
                      </div>
                      <img
                        onClick={() => {
                          onShowFullSize('img2', true);
                        }}
                        src={images[imageIndex].fileUrl}
                      />
                    </div>
                  ) : null}
                </div>
                {images.length > 0 ? (
                  <div
                    className="btn-nav-static-page-image next"
                    onClick={() => {
                      setImageIndex(imageIndex + 1 > maxImageIndex ? 0 : imageIndex + 1);
                    }}
                  >
                    <span className="material-icons">arrow_forward_ios</span>
                  </div>
                ) : null}
              </div>
              <p style={{ textAlign: 'center' }}>Link ảnh:</p>
              <p style={{ textAlign: 'center' }}>{images[imageIndex]?.fileUrl}</p>
              <p style={{ textAlign: 'center' }}>Link thumb:</p>
              <p style={{ textAlign: 'center' }}>{images[imageIndex]?.thumbUrl}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplateDetail;
