import React, { useCallback, useEffect, useState } from 'react';
import { ImageType, LibraryType } from 'src/api/models';
import {
  usePostLibrary,
  usePutLibrary,
  useGetLibraryById,
  useUpdateLibraryAvatar,
  useAddLibraryImage,
  useDeleteLibraryImage,
} from 'src/api/libraryApi';

import { useAddPopup } from 'src/state/application/hooks';
import './LibraryDetail.css';
import Input from 'src/components/Input';
import ButtonComponent from 'src/components/ButtonComponent/ButtonComponent';
import Avatar from 'src/components/Avatar';
import ImageUpload from 'src/components/ImageUpload';

interface ILibraryDetail {
  libraryId: number;
  isDisable: boolean;
  isPopup?: boolean;
  postProcess?: (...args: any[]) => void;
}

const LibraryDetail: React.FC<ILibraryDetail> = (props) => {
  //Function
  const postLibrary = usePostLibrary();
  const putLibrary = usePutLibrary();
  const getLibraryById = useGetLibraryById();
  const addPopup = useAddPopup();
  const updateLibraryAvatar = useUpdateLibraryAvatar();
  const addLibraryImage = useAddLibraryImage();
  const deleteLibraryImage = useDeleteLibraryImage();

  //State
  const [libraryId, setLibraryId] = useState<number>(props.libraryId);
  const [isDisable, setDisable] = useState<boolean>(props.isDisable);
  const [title, setTitle] = useState<string>();
  const [titleError, setTitleError] = useState<string>();
  const [thumbAvatar, setThumbAvatar] = useState<string>();
  const [avatar, setAvatar] = useState<string>();
  const [avatarFile, setAvatarFile] = useState<File>();
  const [images, setImages] = useState<ImageType[]>([]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [displayOrder, setDisplayOrder] = useState<number>();

  //Init
  useEffect(() => {
    if (libraryId) {
      getLibraryById(libraryId)
        .then((data) => {
          setTitle(data.title);
          setThumbAvatar(data.thumbAvatar);
          setAvatar(data.avatar);
          setImages(data.images);
          setDisplayOrder(data.displayOrder);
        })
        .catch((error) => {
          addPopup({
            error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
          });
        });
    }
  }, [addPopup, getLibraryById, libraryId]);

  const validateTitle = () => {
    setTitleError(title && title != '' ? undefined : 'Chưa nhập tiêu đề');
    return title && title != '';
  };

  const onSuccess = (isAdd: boolean, res: LibraryType) => {
    addPopup({
      txn: {
        success: true,
        summary: isAdd ? 'Thêm thư viện ảnh thành công' : 'Sửa thư viện ảnh thành công',
      },
    });
    if (props.postProcess) props.postProcess(res);
  };
  const onSave = () =>
    new Promise((resolve, reject) => {
      if (validateTitle()) {
        const body = {
          libraryId: libraryId,
          title: title,
        };
        const isAdd = !libraryId;
        const api = isAdd ? postLibrary(body) : putLibrary(body);
        api
          .then((res: LibraryType) => {
            setLibraryId(res.libraryId);
            setDisable(true);
            if (isAdd) {
              const uploadApis = [];
              if (avatarFile) uploadApis.push(uploadAvatar(res.libraryId, avatarFile));
              imageFiles
                .filter((f) => f != avatarFile)
                .forEach((image) => uploadApis.push(uploadImage(res.libraryId, image)));
              Promise.all(uploadApis)
                .then((imageRes) => {
                  setImages([...images, ...imageRes]);
                  onSuccess(isAdd, res);
                  resolve(true);
                })
                .catch((error) => {
                  addPopup({
                    error: {
                      message: error.errorMessage,
                      title: 'Tải ảnh thất bại, vui lòng thử lại!',
                    },
                  });
                  resolve(false);
                });
              setImageFiles([]);
            } else {
              onSuccess(isAdd, res);
              resolve(true);
            }
          })
          .catch((error) => {
            addPopup({
              error: {
                message: error.errorMessage,
                title: 'Đã có lỗi xảy ra, vui lòng thử lại!',
              },
            });
            resolve(false);
          });
      } else {
        addPopup(
          {
            error: {
              title: 'Chưa nhập đủ thông tin',
              message: `${titleError ?? ''}`,
            },
          },
          undefined,
          false,
          3000,
        );
        resolve(false);
      }
    });

  const uploadAvatar = useCallback(
    (libraryId: number, file: File) =>
      new Promise<ImageType>((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);
        updateLibraryAvatar(libraryId, formData)
          .then((data) => {
            setAvatar(data.fileUrl);
            setThumbAvatar(data.thumbUrl);
            setAvatarFile(undefined);
            resolve(data);
          })
          .catch((error) => reject(error));
      }),
    [updateLibraryAvatar],
  );

  const uploadImage = useCallback(
    (libraryId: number, file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      return addLibraryImage(libraryId, formData);
    },
    [addLibraryImage],
  );

  //Upload Avatar
  const onChangeAvatar = (file: File) => {
    if (file) {
      if (!libraryId) {
        const url = URL.createObjectURL(file);
        setAvatar(url);
        setThumbAvatar(url);
        setAvatarFile(file);
        setImageFiles([...imageFiles, file]);
      } else {
        uploadAvatar(libraryId, file)
          .then((res) => {
            addPopup({
              txn: {
                success: true,
                summary: 'Tải ảnh thành công',
              },
            });
          })
          .catch((error) => {
            addPopup({
              error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
            });
          });
      }
    }
  };

  //Upload image
  const onAddImage = (file: File) => {
    if (file) {
      if (!libraryId) {
        setImageFiles([...imageFiles, file]);
      } else {
        uploadImage(libraryId, file)
          .then((res) => {
            setImages([...images, res]);
            addPopup({
              txn: {
                success: true,
                summary: 'Tải ảnh thành công',
              },
            });
          })
          .catch((error) => {
            addPopup({
              error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
            });
          });
      }
    }
  };

  const onDeleteLibraryImage = (file: ImageType | File) => {
    if (file instanceof File) {
      setImageFiles(imageFiles.filter((f) => f != file));
    } else {
      deleteLibraryImage(libraryId, file.fileId)
        .then(() => {
          setImages(images.filter((i) => i.fileId != file.fileId));
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa ảnh thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        });
    }
  };
  //End of function

  return (
    <div className="container-fluid library-detail-container">
      <Avatar change={onChangeAvatar} thumbAvatar={thumbAvatar} avatar={avatar} />
      <div className="library-detail-row">
        <Input
          title="Tiêu đề"
          require={true}
          value={title}
          onChange={setTitle}
          disabled={isDisable}
        />
      </div>
      <div className="library-detail-row">
        <Input
          title="Thứ tự"
          require={true}
          value={displayOrder}
          onChange={setDisplayOrder}
          disabled={isDisable}
        />
      </div>
      <ImageUpload
        images={libraryId ? images : imageFiles}
        onDelete={onDeleteLibraryImage}
        addImage={onAddImage}
        showLink={true}
      />
      {isDisable ? null : (
        <ButtonComponent
          icon="save"
          title={!libraryId ? 'THÊM' : 'LƯU'}
          onClick={onSave}
          loader={true}
        />
      )}
    </div>
  );
};

export default LibraryDetail;
