import './Partner.css';
import React, { useState } from 'react';
import { ImageType } from 'src/api/models';
import { useAddPopup } from 'src/state/application/hooks';
import { useEffect } from 'react';
import { useGetPartners, useAddPartnerImage, useDeletePartnerImage } from 'src/api/partnerApi';
import ImageUpload from 'src/components/ImageUpload';

const Partner: React.FC = () => {
  const [images, setImages] = useState<ImageType[]>([]);
  const getImages = useGetPartners();
  const addPopup = useAddPopup();
  const deleteImage = useDeletePartnerImage();
  const addImage = useAddPartnerImage();

  useEffect(() => {
    getImages()
      .then((res) => {
        setImages(res);
      })
      .catch((error) => {
        addPopup({
          error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
        });
      });
  }, []);

  const onDeleteImage = (file: ImageType | File) => {
    if (!(file instanceof File)) {
      deleteImage(file.fileId)
        .then(() => {
          setImages(images.filter((i) => i.fileId != file.fileId));
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa ảnh thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        });
    }
  };

  const onAddImage = (file: File) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      addImage(formData)
        .then((res) => {
          setImages([...images, res]);
          addPopup({
            txn: {
              success: true,
              summary: 'Tải ảnh thành công',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
          });
        });
    }
  };

  return (
    <div className="partner-image-container ">
      <ImageUpload
        images={images}
        showLink={true}
        onDelete={onDeleteImage}
        addImage={onAddImage}
      />
    </div>
  );
};

export default Partner;
