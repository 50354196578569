/* eslint-disable react-hooks/exhaustive-deps */
import './NewsDetails.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Input from 'src/components/Input/Input';
import { useAddPopup, useRemovePopup } from 'src/state/application/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { EnumAction, EventButton, ImageType, NewsType } from 'src/api/models';
import { uniqueId } from 'lodash';
import MonacoEditor from '@monaco-editor/react';
import { Editor } from '@tinymce/tinymce-react';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import { LEFT_MOUSE_BUTTON } from 'src/common/constant/Constant';
import Tabs from 'src/components/Tabs';
import { useGetNewsById, usePostNews, usePutNews } from 'src/api/newsApi';
import { BASE_WEB_URL } from 'src/constants';
import OtherImageUpload from 'src/components/OtherImageUpload';
import useModal from 'src/hooks/useModal';
import Language from 'src/components/Language';

enum TabKey {
  Content,
  SourceCode,
}

const NewsDetails: React.FC = () => {
  //Function
  const getNewsById = useGetNewsById();
  const postNews = usePostNews();
  const putNews = usePutNews();
  const addPopup = useAddPopup();
  const navigate = useNavigate();

  //State
  const [avatar, setAvatar] = useState<string>();
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [vi, setVi] = useState<string>();
  const [en, setEn] = useState<string>();
  const [jp, setJp] = useState<string>();
  const [value, setValue] = useState<string>();
  const [displayOrder, setDisplayOrder] = useState<number>(null);
  //State
  const [tab, setTab] = useState(TabKey.Content);
  const [language, setLanguage] = useState<string>('vi');
  const editorRef = useRef(null);
  const tinyEditorRef = useRef(null);
  const imageModal = useModal(OtherImageUpload);
  //Value
  const params = useParams<{ type: string; newId: string }>();
  const [newId, setNewId] = useState(params?.newId ? Number(params.newId) : undefined);

  const isAdd = Number(params.type) === EnumViewType.Edit && newId == 0;
  const isDisable = Number(params.type) == EnumViewType.View;

  useEffect(() => {
    if (!isAdd) {
      getNewsById(newId)
        .then((data) => {
          setAvatar(data.newAvatar);
          setTitle(data.newTitle);
          setDescription(data.description);
          setDisplayOrder(data.displayOrder);
          setVi(data.vi);
          setEn(data.en);
          setJp(data.jp);
          setDescription(data.description);
          setDisplayOrder(data.displayOrder);
          setValue(
            language == 'vi' ? data.vi ?? '' : language == 'en' ? data.en ?? '' : data.jp ?? '',
          );
        })
        .catch((error) => {
          isAdd
            ? null
            : addPopup({
                error: {
                  message: error.errorMessage,
                  title: 'Đã có lỗi xảy ra!',
                },
              });
        });
    }
  }, [addPopup, getNewsById, newId]);

  //Function Content

  function handleEditorDidMount(editor: unknown) {
    editorRef.current = editor;
  }

  const onSave = useCallback(() => {
    const content =
      tab == TabKey.SourceCode
        ? editorRef.current?.getValue()
        : tinyEditorRef.current?.getContent();
    switch (language) {
      case 'vi':
        setVi(content);
        break;
      case 'en':
        setEn(content);
        break;
      case 'jp':
        setJp(content);
        break;
      default:
        break;
    }
    const body: NewsType = {
      newId: newId,
      newTitle: title,
      newAvatar: avatar,
      vi: language == 'vi' ? content : vi,
      en: language == 'en' ? content : en,
      jp: language == 'jp' ? content : jp,
      description: description,
      displayOrder: displayOrder,
    };
    const api = isAdd ? postNews(body) : putNews(body);

    api
      .then((r) => {
        setNewId(r.newId);
        addPopup({
          txn: {
            success: true,
            summary: isAdd ? 'Thêm thành công!' : 'Sửa thành công!',
          },
        });
      })
      .catch((error) => {
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
  }, [
    tab,
    language,
    newId,
    title,
    avatar,
    vi,
    en,
    jp,
    description,
    displayOrder,
    postNews,
    putNews,
    addPopup,
  ]);
  const listEditToolButton: EventButton[] = [
    {
      name: 'Lưu',
      icon: 'add',
      actionType: isAdd ? EnumAction.Add : EnumAction.Edit,
      buttonClass: 'info static-new-tool-btn',
      action: onSave,
      align: 'center',
    },
    {
      name: 'D.sách ảnh',
      icon: 'image',
      actionType: EnumAction.Add,
      buttonClass: 'info700 static-new-tool-btn cloud_upload-btn',
      action: () => {
        imageModal.handlePresent({}, 'DANH SÁCH ẢNH');
      },
      align: 'center',
    },
  ];

  const listViewToolButton: EventButton[] = [
    {
      name: 'Sửa',
      icon: 'edit',
      actionType: EnumAction.Edit,
      buttonClass: 'info100 static-new-tool-btn',
      action: () => navigate(`${BASE_WEB_URL}/news/${EnumViewType.Edit}/id/${newId}`),
      align: 'center',
    },
  ];

  const tabs = [
    {
      title: 'Content',
      key: TabKey.Content,
      onClick: () => {
        setTab(TabKey.Content);
        const content = editorRef.current?.getValue();
        setValue(content);
      },
    },
    {
      title: 'Source code',
      key: TabKey.SourceCode,
      onClick: () => {
        setTab(TabKey.SourceCode);
        const content = tinyEditorRef.current?.getContent();
        setValue(content);
      },
    },
  ];

  return (
    <>
      <ToolBar
        toolbarName={'Trang tin tức'}
        listRightButton={isDisable ? listViewToolButton : listEditToolButton}
        isBack={true}
        width={'100%'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
      />
      <div className="new-content">
        <div className="new-content-info">
          <div className="new-content-input">
            <Input
              title="Avatar"
              require={true}
              disabled={isDisable}
              value={avatar}
              onChange={setAvatar}
            />
          </div>
          <div className="new-content-input">
            <Input
              title="Tiêu đề"
              require={true}
              disabled={isDisable}
              value={title}
              onChange={setTitle}
            />
          </div>
          <div className="new-content-input">
            <Input
              title="Thứ tự"
              require={true}
              disabled={isDisable}
              value={displayOrder}
              onChange={setDisplayOrder}
            />
          </div>
          <div className="new-content-input">
            <Input
              title="Mô tả"
              require={true}
              disabled={isDisable}
              value={description}
              onChange={setDescription}
            />
          </div>
        </div>
        <div className="inline">
          <Tabs activeTab={tab} tabs={tabs} />
          <Language
            className="new-language"
            language={language}
            setLanguage={(lang) => {
              const content =
                tab == TabKey.SourceCode
                  ? editorRef.current?.getValue()
                  : tinyEditorRef.current?.getContent();

              switch (language) {
                case 'vi':
                  setVi(content);
                  break;
                case 'en':
                  setEn(content);
                  break;
                case 'jp':
                  setJp(content);
                  break;
                default:
                  break;
              }
              setLanguage(lang);
              let nValue = lang == 'vi' ? vi ?? '' : lang == 'en' ? en ?? '' : jp ?? '';
              if (nValue == value) nValue += ' ';
              console.log(nValue);
              setValue(nValue);
            }}
          />
        </div>

        {tab == TabKey.SourceCode ? (
          <MonacoEditor
            height="690px"
            defaultLanguage="html"
            value={value}
            onMount={handleEditorDidMount}
            options={{
              readOnly: isDisable,
            }}
          />
        ) : (
          <Editor
            disabled={isDisable}
            apiKey={'9rjkn0nooxthjws4ylk4s6ogwe2x1ll74eozkdq1or2maa59'}
            onInit={(evt, editor) => (tinyEditorRef.current = editor)}
            initialValue={value}
            init={{
              min_height: 690,
              menubar: true,
              plugins: [
                'preview',
                'lists',
                'advlist',
                'anchor',
                'link',
                'autolink',
                'autoresize',
                'charmap',
                'code',
                'codesample',
                'directionality',
                'emoticons',
                'fullscreen',
                'image',
                'insertdatetime',
                'media',
                'nonbreaking',
                'pagebreak',
                'quickbars',
                'searchreplace',
                'table',
                'visualblocks',
                'visualchars',
                'wordcount',
                'help',
              ],
              toolbar:
                'preview | undo redo | forecolor | formatselect | bold italic backcolor | blocks | blockquote | hr | ' +
                'align | bullist numlist outdent indent | alignjustify | visualchars | wordcount' +
                'link | charmap | code | ltr rtl | emoticons | fullscreen | image | table |' +
                'nonbreaking | pagebreak | print | view |searchreplace | visualblocks | insertdatetime | media | paste | wordcount |' +
                'rotateleft rotateright | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />
        )}
      </div>
    </>
  );
};

export default NewsDetails;
