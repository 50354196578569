import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { PageData, ExperienceType } from './models';

/***********************************/
export const useGetExperience = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `ccore/rest-api/experience`,
      method: 'get',
    }) as Promise<PageData<ExperienceType>>;
  }, [fetch]);
};

export const usePostExperience = () => {
  const fetch = useFetch();
  return useCallback(
    (data: ExperienceType) => {
      return fetch({
        url: 'ccore/rest-api/experience',
        method: 'post',
        data: data,
      }) as Promise<ExperienceType>;
    },
    [fetch],
  );
};

export const useGetExperienceById = () => {
  const fetch = useFetch();
  return useCallback(
    (experienceId: number) => {
      return fetch({
        url: `ccore/rest-api/experience/${experienceId}`,
        method: 'get',
      }) as Promise<ExperienceType>;
    },
    [fetch],
  );
};

export const usePutExperience = () => {
  const fetch = useFetch();
  return useCallback(
    (data: ExperienceType) => {
      return fetch({
        url: `ccore/rest-api/experience`,
        method: 'put',
        data: data,
      }) as Promise<ExperienceType>;
    },
    [fetch],
  );
};

export const useDeleteExperience = () => {
  const fetch = useFetch();
  return useCallback(
    (experienceId: number) => {
      return fetch({
        url: `ccore/rest-api/experience/${experienceId}`,
        method: 'delete',
      }) as Promise<boolean>;
    },
    [fetch],
  );
};
