import './Experience.css';
import { useAddPopup } from 'src/state/application/hooks';
import { EnumAction, EnumDataType, EventButton, ExperienceType } from 'src/api/models';
import ToolBar from 'src/components/ToolBar/ToolBar';
import { useEffect, useState } from 'react';
import Table from 'src/components/Table/Table';
import { useNavigate } from 'react-router-dom';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import { useGetExperience, useDeleteExperience } from 'src/api/experienceApi';
import { BASE_WEB_URL } from 'src/constants';
import useModal from 'src/hooks/useModal';
import ConfirmModal from 'src/components/ConfirmModal/ConfirmModal';
import { TableColumnType } from 'src/components/Table/TableHeader/TableHeader';

const Experience: React.FC = () => {
  const header = {
    header: [
      {
        code: 'avatar',
        dataType: EnumDataType.Image,
        isOptions: false,
        title: 'Avatar',
      },
      {
        code: 'experienceTitle',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Tiêu đề',
      },
      {
        code: 'description',
        dataType: EnumDataType.Text,
        isOptions: false,
        title: 'Mô tả',
      },
      {
        code: 'displayOrder',
        dataType: EnumDataType.Int,
        isOptions: false,
        title: 'Thứ tự',
        cellCss: {
          textAlign: 'center',
        },
      },
    ] as TableColumnType[],
  };

  //Function
  const addPopup = useAddPopup();
  const getExperience = useGetExperience();
  const deleteExperience = useDeleteExperience();
  const navigate = useNavigate();
  const [reloadFlag, setReloadFlag] = useState(false);
  const [data, setData] = useState<ExperienceType[]>([]);
  const confirmModal = useModal(ConfirmModal);

  useEffect(() => {
    getExperience()
      .then((data) => {
        setData(data.items);
      })
      .catch((error) => {
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
  }, [getExperience, reloadFlag]);

  const onDelete = (experienceId: number) => {
    const onConfirm = () => {
      deleteExperience(experienceId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa thành công!',
            },
          });
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
        })
        .finally(() => setReloadFlag(!reloadFlag));
    };

    const listButton: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    confirmModal.handlePresent(
      {
        question: 'Bạn có chắc muốn xóa bài viết này?',
        listActionButton: listButton,
        postProcess: confirmModal.handleDismiss,
      },
      'XÓA BÀI VIẾT',
    );
  };

  const menuContext = (item: ExperienceType) => [
    {
      name: 'Chi tiết',
      icon: 'info',
      actionType: EnumAction.Edit,
      action: () => openDetail(EnumViewType.View, item.experienceId),
      buttonClass: 'info',
      align: 'left',
    },
    {
      name: 'Sửa',
      icon: 'auto_fix_high',
      actionType: EnumAction.Edit,
      action: () => openDetail(EnumViewType.Edit, item.experienceId),
      buttonClass: 'info',
      align: '',
    },
    {
      name: 'Xóa',
      icon: 'delete',
      actionType: EnumAction.Edit,
      action: () => onDelete(item.experienceId),
      buttonClass: 'info',
      align: '',
    },
  ];

  const openDetail = (type: EnumViewType, experienceId?: number) => {
    const url = `${BASE_WEB_URL}/experience/${type}/id/${experienceId ?? 0}`;
    navigate(url);
  };

  const listButton: EventButton[] = [
    {
      name: 'Thêm',
      icon: 'add',
      actionType: EnumAction.View,
      buttonClass: 'info100',
      action: () => openDetail(EnumViewType.Edit),
      align: 'center',
    },
  ];
  //End of toolbar

  return (
    <div className="experience-container">
      <ToolBar
        toolbarName={'Danh sách bài viết trải nghiệm'}
        listRightButton={listButton}
        width={'100%'}
        height={'50px'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
      />
      <Table
        display={header}
        isInput={false}
        menuContext={menuContext}
        data={data}
        onDoubleClick={(item) => openDetail(EnumViewType.View, item.experienceId)}
      />
    </div>
  );
};

export default Experience;
