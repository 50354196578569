import './Language.css';
import React from 'react';

interface ILanguageProps {
  className?: string;
  language?: string;
  setLanguage?: (value: string) => void;
}

const Language: React.FC<ILanguageProps> = (props) => {
  const { className, language, setLanguage } = props;

  return (
    <div className={`${className ? className : ''}`}>
      <span
        className={`flag-icon flag-icon-gb ${language == 'en' ? 'active' : 'deactive'}`}
        onClick={() => setLanguage('en')}
      >
        &#8203;
      </span>
      <span
        className={`flag-icon flag-icon-vn ${language == 'vi' ? 'active' : 'deactive'}`}
        onClick={() => setLanguage('vi')}
      >
        &#8203;
      </span>
      <span
        className={`flag-icon flag-icon-jp ${language == 'jp' ? 'active' : 'deactive'}`}
        onClick={() => setLanguage('jp')}
      >
        &#8203;
      </span>
    </div>
  );
};

export default Language;
