import React, { useCallback, useEffect, useRef, useState } from 'react';
import Input from 'src/components/Input/Input';
import { useAddPopup, useRemovePopup } from 'src/state/application/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import ToolBar from 'src/components/ToolBar/ToolBar';
import {
  HtmlColumnsType,
  HtmlSimpleParameterType,
  HtmlTableParameterType,
  PrintedTemplateType,
  EnumAction,
  EnumHtmlTemplateDataType,
  EventButton,
  ImageType,
} from 'src/api/models';
import { uniqueId } from 'lodash';
import MonacoEditor from '@monaco-editor/react';
import { Editor } from '@tinymce/tinymce-react';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import { LEFT_MOUSE_BUTTON } from 'src/common/constant/Constant';
import Tabs from 'src/components/Tabs';
import { BASE_SETTING_URL } from 'src/constants';
import {
  useDeletePrintedTemplateImage,
  useGetPrintedTemplateById,
  useGetPrintedTemplateImage,
  usePostPrintedTemplate,
  usePostPrintedTemplateImage,
  usePutPrintedTemplate,
} from 'src/api/printedTemplateApi';
import './PrintedTemplateDetail.css';

enum TabKey {
  Content,
  SourceCode,
}

const PrintedTemplateDetail: React.FC = () => {
  //Function
  const getPrintedTemplateById = useGetPrintedTemplateById();
  const postPrintedTemplate = usePostPrintedTemplate();
  const putPrintedTemplate = usePutPrintedTemplate();
  const addPopup = useAddPopup();
  const removePopup = useRemovePopup();

  //Value
  const navigate = useNavigate();
  const tinyEditorRef = useRef(null);

  //State
  const [printedTemplateKey, setPrintedTemplateKey] = useState<string>('');
  const [printedTemplateTitle, setPrintedTemplateTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [displayOrder, setDisplayOrder] = useState<number>(null);
  //State
  const [images, setImages] = useState<ImageType[] | null>([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [maxImageIndex, setMaxImageIndex] = useState(0);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [tab, setTab] = useState(TabKey.SourceCode);
  //Param state
  const [printedSimpleParameter, setPrintedSimpleParameter] =
    useState<HtmlSimpleParameterType[]>(null);
  const [printedTableParameter, setPrintedTableParameter] =
    useState<HtmlTableParameterType[]>(null);

  //Function
  const [isShowFullSizeImg2, setShowFullSizeImg2] = useState(false);
  const getPrintedTemplateImage = useGetPrintedTemplateImage();
  const addPrintedTemplateImage = usePostPrintedTemplateImage();
  const deletePrintedTemplateImage = useDeletePrintedTemplateImage();
  const editorRef = useRef(null);

  //Value
  const params = useParams<{ type: string; printedTemplateId: string }>();
  const printedTemplateId = Number(params.printedTemplateId);
  const isAdd = Number(params.type) === EnumViewType.Edit && printedTemplateId == 0;
  const isDisable = Number(params.type) == EnumViewType.View;

  useEffect(() => {
    if (!isAdd) {
      getPrintedTemplateById(printedTemplateId)
        .then((data) => {
          setPrintedTemplateKey(data.printedTemplateKey);
          setPrintedTemplateTitle(data.printedTemplateTitle);
          setValue(data.printedTemplateValue);
          setDescription(data.description);
          setDisplayOrder(data.displayOrder);
          setPrintedSimpleParameter(data.printedSimpleParameter);
          setPrintedTableParameter(data.printedTableParameter);
        })
        .catch((error) => {
          isAdd
            ? null
            : addPopup({
                error: {
                  message: error.errorMessage,
                  title: 'Đã có lỗi xảy ra!',
                },
              });
        });
    }
    getPrintedTemplateImage()
      .then((data) => {
        setImages(data);
        setMaxImageIndex(data.length - 1);
      })
      .catch((error) => {
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
  }, [
    addPopup,
    isAdd,
    reloadFlag,
    printedTemplateId,
    getPrintedTemplateById,
    getPrintedTemplateImage,
  ]);

  //Function Content

  function handleEditorDidMount(editor: unknown) {
    editorRef.current = editor;
  }

  const onChangeSimpleParam = (value: string, index: number, type: string) => {
    if (printedSimpleParameter) {
      const temp = printedSimpleParameter.filter(() => true);
      if (type == 'PARAMETERNAME') {
        temp[index].parameterName = value;
      } else if (type == 'DESCRIPTION') {
        temp[index].description = value;
      } else if (type == 'DATATYPE') {
        temp[index].dataType = Number(value);
      }
      setPrintedSimpleParameter(temp);
    }
  };

  const onChangeTableParam = (
    value: string,
    type: string,
    mainIndex: number,
    columnIndex?: number,
  ) => {
    if (printedTableParameter) {
      const temp = printedTableParameter.filter(() => true);
      if (type == 'TABLENAME') {
        temp[mainIndex].tableName = value;
      } else if (type == 'COLUMNTITLE') {
        temp[mainIndex].columns[columnIndex].columnTitle = value;
      } else if (type == 'COLUMNNAME') {
        temp[mainIndex].columns[columnIndex].columnName = value;
      } else if (type == 'DATATYPE') {
        temp[mainIndex].columns[columnIndex].dataType = Number(value);
      } else if (type == 'COLUMNCSS') {
        temp[mainIndex].columns[columnIndex].columnCss = value;
      } else if (type == 'CELLCSS') {
        temp[mainIndex].columns[columnIndex].cellCss = value;
      } else if (type == 'TABLECSS') {
        temp[mainIndex].tableCss = value;
      } else if (type == 'ROWCSS') {
        temp[mainIndex].rowCss = value;
      }
      setPrintedTableParameter(temp);
    }
  };

  const onDeleteColumn = (mainIndex: number, columnIndex: number) => {
    if (printedTableParameter) {
      const temp = printedTableParameter.filter(() => true);
      if (temp[mainIndex].columns.length >= 2) {
        temp[mainIndex].columns.splice(columnIndex, 1);
        setPrintedTableParameter(temp);
      } else {
        temp.splice(mainIndex, 1);
        setPrintedTableParameter(temp);
      }
    }
  };

  const onAddColumn = (mainIndex: number) => {
    const arr = printedTableParameter.filter(() => true);
    const temp: HtmlColumnsType = {
      columnTitle: '',
      columnName: '',
      dataType: EnumHtmlTemplateDataType.Text,
      columnCss: '',
    };
    arr[mainIndex].columns.push(temp);
    setPrintedTableParameter(arr);
  };

  const onDeleteSimpleParam = (index: number) => {
    if (printedSimpleParameter) {
      const temp = printedSimpleParameter.filter(() => true);
      temp.splice(index, 1);
      setPrintedSimpleParameter(temp);
    }
  };

  const onAddSimpleParam = () => {
    if (printedSimpleParameter) {
      const arr = printedSimpleParameter.filter(() => true);
      const temp: HtmlSimpleParameterType = {
        parameterName: '',
        description: '',
        dataType: EnumHtmlTemplateDataType.Text,
      };
      arr.push(temp);
      setPrintedSimpleParameter(arr);
    } else {
      const arr: HtmlSimpleParameterType[] = [
        {
          parameterName: '',
          description: '',
          dataType: EnumHtmlTemplateDataType.Text,
        },
      ];
      setPrintedSimpleParameter(arr);
    }
  };

  const onAddTableParam = () => {
    if (printedTableParameter) {
      const arr = printedTableParameter.filter(() => true);
      const temp: HtmlTableParameterType = {
        tableName: '',
        columns: [
          {
            columnTitle: '',
            columnName: '',
            dataType: EnumHtmlTemplateDataType.Text,
            columnCss: '',
            cellCss: '',
          },
        ],
        tableCss: '',
        rowCss: '',
      };
      arr.push(temp);
      setPrintedTableParameter(arr);
    } else {
      const arr: HtmlTableParameterType[] = [
        {
          tableName: '',
          columns: [
            {
              columnTitle: '',
              columnName: '',
              dataType: EnumHtmlTemplateDataType.Text,
              columnCss: '',
              cellCss: '',
            },
          ],
          tableCss: '',
          rowCss: '',
        },
      ];
      setPrintedTableParameter(arr);
    }
  };

  const onAddPrintedTemPlate = useCallback(() => {
    const pageValue =
      tab == TabKey.SourceCode
        ? editorRef.current.getValue()
        : tinyEditorRef.current.getContent();

    const PrintedTemplate: PrintedTemplateType = {
      displayOrder: displayOrder,
      printedTemplateKey: printedTemplateKey,
      printedTemplateTitle: printedTemplateTitle,
      printedTemplateValue: pageValue,
      printedSimpleParameter: printedSimpleParameter,
      printedTableParameter: printedTableParameter,
      description: description,
    };
    postPrintedTemplate(PrintedTemplate)
      .then((r) => {
        addPopup({
          txn: {
            success: true,
            summary: 'Thêm thành công!',
          },
        });
        setValue(r.printedTemplateValue);
      })
      .catch((error) => {
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
  }, [
    tab,
    displayOrder,
    printedTemplateKey,
    printedTemplateTitle,
    printedSimpleParameter,
    printedTableParameter,
    description,
    postPrintedTemplate,
    removePopup,
    addPopup,
  ]);

  const onEditPrintedTemPlate = useCallback(() => {
    const pageValue =
      tab == TabKey.SourceCode
        ? editorRef.current.getValue()
        : tinyEditorRef.current.getContent();

    const PrintedTemplate: PrintedTemplateType = {
      displayOrder: displayOrder,
      printedTemplateId: printedTemplateId,
      printedTemplateKey: printedTemplateKey,
      printedTemplateTitle: printedTemplateTitle,
      printedTemplateValue: pageValue,
      printedSimpleParameter: printedSimpleParameter,
      printedTableParameter: printedTableParameter,
      description: description,
    };
    putPrintedTemplate(PrintedTemplate)
      .then((r) => {
        console.log('=======r=====');
        console.log(r);
        addPopup({
          txn: {
            success: true,
            summary: 'Sửa thành công',
          },
        });
        setValue(r.printedTemplateValue);
      })
      .catch((error) => {
        console.log('=======errr=====');
        console.log(error);
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
  }, [
    tab,
    displayOrder,
    printedTemplateId,
    printedTemplateKey,
    printedTemplateTitle,
    printedSimpleParameter,
    printedTableParameter,
    description,
    putPrintedTemplate,
    removePopup,
    addPopup,
  ]);
  //End of function

  //Function Image
  const onAddImage = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.size <= 55242880) {
        const formData = new FormData();
        formData.append('name', '');
        formData.append('file', event.target.files[0]);

        addPrintedTemplateImage(formData)
          .then(() => {
            addPopup({
              txn: {
                success: true,
                summary: 'Upload ảnh thành công!',
              },
            });
            setReloadFlag(!reloadFlag);
          })
          .catch((error) => {
            addPopup({
              error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
            });
          });
        addPopup({
          txn: {
            success: true,
            summary: 'Vui lòng chờ upload ảnh!',
          },
        });
      } else {
        addPopup({
          error: { message: 'Ảnh tối đa 5MB', title: 'Đã có lỗi xảy ra!' },
        });
      }
    }
  };

  const onShowFullSize = (name: string, isShow: boolean) => {
    if (name == 'img2') setShowFullSizeImg2(isShow);
  };

  const onDeletePrintedTemplateImage = () => {
    const onCancel = () => {
      console.log('cancel');
    };

    const onConfirm = () => {
      deletePrintedTemplateImage(images[imageIndex].fileId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa ảnh thành công!',
            },
          });
          setImageIndex(0);
          setMaxImageIndex(maxImageIndex - 1);
          setReloadFlag(!reloadFlag);
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
          setImageIndex(0);
          setReloadFlag(!reloadFlag);
        });
      addPopup({
        txn: {
          success: true,
          summary: 'Đợi một chút!',
        },
      });
    };
    const listToDo: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        action: onCancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    // addPopup(
    //   {
    //     confirm: {
    //       width: '25vw',
    //       height: '20vh',
    //       question: 'Bạn có chắc muốn xóa ảnh này?',
    //       listActionButton: listToDo,
    //     },
    //   },
    //   'delete-image',
    // );
  };

  const onBackToPrintedTemplate = (e: MouseEvent) => {
    if (e.button === LEFT_MOUSE_BUTTON) {
      navigate(-1);
    }
  };

  const onChangeEditMode = (e: MouseEvent) => {
    if (e.button === LEFT_MOUSE_BUTTON) {
      navigate(
        `${BASE_SETTING_URL}/printed-template/${EnumViewType.Edit}/id/${printedTemplateId}`,
      );
    }
  };
  //End of function

  const listEditToolButton: EventButton[] = [
    {
      name: 'Trở về',
      icon: 'arrow_back',
      actionType: EnumAction.View,
      buttonClass: 'info100 static-page-tool-btn',
      action: onBackToPrintedTemplate,
      align: 'center',
    },
    {
      name: 'Lưu',
      icon: 'add',
      actionType: isAdd ? EnumAction.Add : EnumAction.Edit,
      buttonClass: 'info static-page-tool-btn',
      action: isAdd ? onAddPrintedTemPlate : onEditPrintedTemPlate,
      align: 'center',
    },
    {
      name: 'Thêm ảnh',
      icon: 'cloud_upload',
      actionType: EnumAction.Add,
      buttonClass: 'info700 static-page-tool-btn cloud_upload-btn',
      action: onAddImage,
      align: 'center',
      file: true,
    },
  ];

  const listViewToolButton: EventButton[] = [
    {
      name: 'Trở về',
      icon: 'arrow_back',
      actionType: EnumAction.View,
      buttonClass: 'info100 static-page-tool-btn',
      action: onBackToPrintedTemplate,
      align: 'center',
    },
    {
      name: 'Sửa',
      icon: 'edit',
      actionType: EnumAction.Edit,
      buttonClass: 'info100 static-page-tool-btn',
      action: onChangeEditMode,
      align: 'center',
    },
  ];

  const tabs = [
    {
      title: 'Content',
      key: TabKey.Content,
      onClick: () => {
        if (tab == TabKey.SourceCode) {
          const content = editorRef.current.getValue();
          setValue(content);
        }
        setTab(TabKey.Content);
      },
    },
    {
      title: 'Source code',
      key: TabKey.SourceCode,
      onClick: () => {
        if (tab == TabKey.Content) {
          const content = tinyEditorRef.current.getContent();
          setValue(content);
        }
        setTab(TabKey.SourceCode);
      },
    },
  ];

  //Component
  const paramComponent = () => {
    return (
      <div className="printed-template-detail-param-container">
        <div className="table-param-container">
          <div className="table-param">
            <div className="table-param-component title">tableName</div>
            <div className="table-param-component title">tableCss</div>
            <div className="table-param-component title">rowCss</div>
            <div className="table-param-detail">
              <div className="table-param-detail-component">
                <div className="table-param-component title">columnTitle</div>
                <div className="table-param-component title">columnName</div>
                <div className="table-param-component title">dataType</div>
                <div className="table-param-component title">columnCss</div>
                <div className="table-param-component title">cellCss</div>
                <div className="printed-template-btn-component title"></div>
              </div>
            </div>
          </div>
          {printedTableParameter
            ? printedTableParameter.map((value: HtmlTableParameterType, mainIndex: number) => {
                return (
                  <div className={`table-param ${mainIndex % 2 == 0 ? 'chan' : 'le'}`}>
                    <div className="table-param-component">
                      <input
                        type="text"
                        value={value.tableName}
                        onChange={(event) => {
                          onChangeTableParam(event.target.value, 'TABLENAME', mainIndex);
                        }}
                      />
                    </div>
                    <div className="table-param-component">
                      <input
                        type="text"
                        value={value.tableCss}
                        onChange={(event) => {
                          onChangeTableParam(event.target.value, 'TABLECSS', mainIndex);
                        }}
                      />
                    </div>
                    <div className="table-param-component">
                      <input
                        type="text"
                        value={value.rowCss}
                        onChange={(event) => {
                          onChangeTableParam(event.target.value, 'ROWCSS', mainIndex);
                        }}
                      />
                    </div>
                    <div className="table-param-detail">
                      {value.columns.map((v: HtmlColumnsType, columnIndex: number) => {
                        return (
                          <div className="table-param-detail-component">
                            <div className="table-param-component">
                              <input
                                type="text"
                                value={v.columnTitle}
                                onChange={(event) => {
                                  onChangeTableParam(
                                    event.target.value,
                                    'COLUMNTITLE',
                                    mainIndex,
                                    columnIndex,
                                  );
                                }}
                              />
                            </div>
                            <div className="table-param-component">
                              <input
                                type="text"
                                value={v.columnName}
                                onChange={(event) => {
                                  onChangeTableParam(
                                    event.target.value,
                                    'COLUMNNAME',
                                    mainIndex,
                                    columnIndex,
                                  );
                                }}
                              />
                            </div>
                            <div className="table-param-component">
                              <select
                                value={v.dataType}
                                onChange={(event) => {
                                  onChangeTableParam(
                                    event.target.value,
                                    'DATATYPE',
                                    mainIndex,
                                    columnIndex,
                                  );
                                }}
                              >
                                <option value={EnumHtmlTemplateDataType.Text}>Text</option>
                                <option value={EnumHtmlTemplateDataType.Int}>Int</option>
                                <option value={EnumHtmlTemplateDataType.Date}>Date</option>
                                <option value={EnumHtmlTemplateDataType.PhoneNumber}>
                                  PhoneNumber
                                </option>
                                <option value={EnumHtmlTemplateDataType.Email}>Email</option>
                                <option value={EnumHtmlTemplateDataType.Boolean}>
                                  Boolean
                                </option>
                                <option value={EnumHtmlTemplateDataType.Percentage}>
                                  Percentage
                                </option>
                                <option value={EnumHtmlTemplateDataType.BigInt}>BigInt</option>
                                <option value={EnumHtmlTemplateDataType.Decimal}>
                                  Decimal
                                </option>
                                <option value={EnumHtmlTemplateDataType.Month}>Month</option>
                                <option value={EnumHtmlTemplateDataType.QuarterOfYear}>
                                  QuarterOfYear
                                </option>
                                <option value={EnumHtmlTemplateDataType.Year}>Year</option>
                                <option value={EnumHtmlTemplateDataType.DateRange}>
                                  DateRange
                                </option>
                                <option value={EnumHtmlTemplateDataType.Image}>Image</option>
                              </select>
                            </div>
                            <div className="table-param-component">
                              <input
                                type="text"
                                value={v.columnCss}
                                onChange={(event) => {
                                  onChangeTableParam(
                                    event.target.value,
                                    'COLUMNCSS',
                                    mainIndex,
                                    columnIndex,
                                  );
                                }}
                              />
                            </div>
                            <div className="table-param-component">
                              <input
                                type="text"
                                value={v.cellCss}
                                onChange={(event) => {
                                  onChangeTableParam(
                                    event.target.value,
                                    'CELLCSS',
                                    mainIndex,
                                    columnIndex,
                                  );
                                }}
                              />
                            </div>
                            <div
                              className="printed-template-btn-component title"
                              onClick={() => {
                                onDeleteColumn(mainIndex, columnIndex);
                              }}
                            >
                              <i className="fas fa-trash"></i>
                            </div>
                          </div>
                        );
                      })}
                      <div className="table-param-detail-component">
                        <div
                          className="printed-template-add-btn"
                          onClick={() => {
                            onAddColumn(mainIndex);
                          }}
                        >
                          Add column
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
          <div className="table-param">
            <div className="printed-template-add-btn" onClick={onAddTableParam}>
              Add table param
            </div>
          </div>
        </div>
        <div className="simple-param-container">
          <div className="simple-param">
            <div className="simple-param-component title">parameterName</div>
            <div className="simple-param-component title">description</div>
            <div className="simple-param-component title">dataType</div>
            <div className="printed-template-btn-component title"></div>
          </div>
          {printedSimpleParameter
            ? printedSimpleParameter.map((value: HtmlSimpleParameterType, index: number) => {
                return (
                  <div className="simple-param">
                    <div className="simple-param-component">
                      <input
                        type="text"
                        value={value.parameterName}
                        onChange={(event) => {
                          onChangeSimpleParam(event.target.value, index, 'PARAMETERNAME');
                        }}
                      />
                    </div>
                    <div className="simple-param-component">
                      <input
                        type="text"
                        value={value.description}
                        onChange={(event) => {
                          onChangeSimpleParam(event.target.value, index, 'DESCRIPTION');
                        }}
                      />
                    </div>
                    <div className="simple-param-component">
                      <select
                        value={value.dataType}
                        onChange={(event) => {
                          onChangeSimpleParam(event.target.value, index, 'DATATYPE');
                        }}
                      >
                        <option value={EnumHtmlTemplateDataType.Text}>Text</option>
                        <option value={EnumHtmlTemplateDataType.Int}>Int</option>
                        <option value={EnumHtmlTemplateDataType.Date}>Date</option>
                        <option value={EnumHtmlTemplateDataType.PhoneNumber}>
                          PhoneNumber
                        </option>
                        <option value={EnumHtmlTemplateDataType.Email}>Email</option>
                        <option value={EnumHtmlTemplateDataType.Boolean}>Boolean</option>
                        <option value={EnumHtmlTemplateDataType.Percentage}>Percentage</option>
                        <option value={EnumHtmlTemplateDataType.BigInt}>BigInt</option>
                        <option value={EnumHtmlTemplateDataType.Decimal}>Decimal</option>
                        <option value={EnumHtmlTemplateDataType.Month}>Month</option>
                        <option value={EnumHtmlTemplateDataType.QuarterOfYear}>
                          QuarterOfYear
                        </option>
                        <option value={EnumHtmlTemplateDataType.Year}>Year</option>
                        <option value={EnumHtmlTemplateDataType.DateRange}>DateRange</option>
                        <option value={EnumHtmlTemplateDataType.Image}>Image</option>
                      </select>
                    </div>
                    <div
                      className="printed-template-btn-component title"
                      onClick={() => {
                        onDeleteSimpleParam(index);
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </div>
                  </div>
                );
              })
            : null}
          <div className="simple-param">
            <div className="printed-template-add-btn" onClick={onAddSimpleParam}>
              Add simple param
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      {/* <ToolBar
        toolbarName={'Trang thông tin'}
        listRightButton={isDisable ? listViewToolButton : listEditToolButton}
        width={'100%'}
        height={'50px'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
      /> */}
      <div className="row">
        <div className="col-12 static-page-image">
          <div className="container-fluid">
            <div className="row justify-content-center">
              {images.map((img: ImageType, index: number) => {
                return (
                  <div
                    key={uniqueId()}
                    className={`col-1 p-0 m-1 static-page-thumb-img-child ${
                      imageIndex === index ? 'focus-img' : ''
                    }`}
                    onClick={() => {
                      setImageIndex(index);
                    }}
                  >
                    <img className="img-item" src={img.thumbUrl} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {paramComponent()}
        <div className="col-12 add-static-page-view">
          <div className="row">
            <div className="col-9 content">
              <div className="value-content">
                <div className="row content-row">
                  <div className="col-3">
                    <div className="add-static-page-child">
                      <span className="mr-2">
                        Key:<span style={{ color: 'red' }}>*</span>
                      </span>
                      <Input
                        disabled={isDisable}
                        value={printedTemplateKey}
                        onChange={setPrintedTemplateKey}
                      />
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="add-static-page-child">
                      <span className="mr-2">
                        Tiêu đề:<span style={{ color: 'red' }}>*</span>
                      </span>
                      <Input
                        disabled={isDisable}
                        value={printedTemplateTitle}
                        onChange={setPrintedTemplateTitle}
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="add-static-page-child">
                      <span className="mr-2">
                        Thứ tự:<span style={{ color: 'red' }}>*</span>
                      </span>
                      <Input
                        disabled={isDisable}
                        value={displayOrder}
                        onChange={setDisplayOrder}
                      />
                    </div>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-12">
                    <div className="add-static-page-child">
                      <span className="mr-2">Mô tả:</span>
                      <Input
                        disabled={isDisable}
                        value={description}
                        onChange={setDescription}
                      />
                    </div>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-12">
                    <div className="add-static-page-child">
                      <span className="mr-2">
                        Nội dung:<span style={{ color: 'red' }}>*</span>
                      </span>

                      <Tabs activeTab={tab} tabs={tabs} />
                      <div className="tabs-content">
                        {tab == TabKey.SourceCode ? (
                          <MonacoEditor
                            height="90vh"
                            defaultLanguage="html"
                            value={value}
                            onMount={handleEditorDidMount}
                            options={{
                              readOnly: isDisable,
                            }}
                          />
                        ) : (
                          <Editor
                            disabled={isDisable}
                            apiKey={'9rjkn0nooxthjws4ylk4s6ogwe2x1ll74eozkdq1or2maa59'}
                            onInit={(evt, editor) => (tinyEditorRef.current = editor)}
                            initialValue={value}
                            init={{
                              height: 'fit=content',
                              menubar: true,
                              plugins: [
                                'link image code textcolor charmap preview anchor searchreplace visualblocks fullscreen insertdatetime media table code wordcount help align',
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount',
                              ],
                              toolbar:
                                'undo redo print spellcheckdialog formatpainter | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image addcomment showcomments  | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat',
                              content_style:
                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 content">
              <div className="static-page-fullsize-img">
                {images.length > 0 ? (
                  <div
                    className="btn-nav-static-page-image back"
                    onClick={() => {
                      setImageIndex(imageIndex - 1 >= 0 ? imageIndex - 1 : maxImageIndex);
                    }}
                  >
                    <span className="material-icons">arrow_back_ios_new</span>
                  </div>
                ) : null}
                <div
                  className={`static-page-img-child center ${
                    isShowFullSizeImg2 ? 'full-size' : ''
                  }`}
                >
                  {images.length > 0 ? (
                    <div
                      className="btn-delete-static-page-image"
                      title="Xóa"
                      onClick={() => {
                        onDeletePrintedTemplateImage();
                      }}
                    >
                      <span className="material-icons">delete</span>
                    </div>
                  ) : null}
                  {images.length > 0 ? (
                    <div>
                      <div
                        className="btn-zoom-out-static-page-image"
                        title="Thu nhỏ"
                        onClick={() => {
                          onShowFullSize('img2', false);
                        }}
                      >
                        <span className="material-icons">close</span>
                      </div>
                      <img
                        onClick={() => {
                          onShowFullSize('img2', true);
                        }}
                        src={images[imageIndex].fileUrl}
                      />
                    </div>
                  ) : null}
                </div>
                {images.length > 0 ? (
                  <div
                    className="btn-nav-static-page-image next"
                    onClick={() => {
                      setImageIndex(imageIndex + 1 > maxImageIndex ? 0 : imageIndex + 1);
                    }}
                  >
                    <span className="material-icons">arrow_forward_ios</span>
                  </div>
                ) : null}
              </div>
              <p style={{ textAlign: 'center' }}>Link ảnh:</p>
              <p style={{ textAlign: 'center' }}>{images[imageIndex]?.fileUrl}</p>
              <p style={{ textAlign: 'center' }}>Link thumb:</p>
              <p style={{ textAlign: 'center' }}>{images[imageIndex]?.thumbUrl}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintedTemplateDetail;
