import React, { useCallback, useEffect, useRef, useState } from 'react';
import Input from 'src/components/Input/Input';
import { useAddPopup, useRemovePopup } from 'src/state/application/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import ToolBar from 'src/components/ToolBar/ToolBar';
import {
  EnumAction,
  EnumNotificationTemplateDataType,
  EventButton,
  ImageType,
  NotificationParameterType,
  NotificationTemplateType,
} from 'src/api/models';
import { uniqueId } from 'lodash';
import MonacoEditor from '@monaco-editor/react';
import { Editor } from '@tinymce/tinymce-react';
import { EnumViewType } from 'src/common/enum/EnumViewType';
import { LEFT_MOUSE_BUTTON } from 'src/common/constant/Constant';
import Tabs from 'src/components/Tabs';
import { BASE_SETTING_URL } from 'src/constants';
import {
  useDeleteNotificationTemplateImage,
  useGetNotificationTemplateById,
  useGetNotificationTemplateImage,
  usePostNotificationTemplate,
  usePostNotificationTemplateImage,
  usePutNotificationTemplate,
} from 'src/api/notificatoinTemplateApi';

enum TabKey {
  Content,
  SourceCode,
}

const NotificationTemplateDetail: React.FC = () => {
  //Function
  const getNotificaionTemplateById = useGetNotificationTemplateById();
  const postNotificationTemplate = usePostNotificationTemplate();
  const putNotificationTemplate = usePutNotificationTemplate();
  const addPopup = useAddPopup();
  const removePopup = useRemovePopup();

  //Value
  const navigate = useNavigate();
  const tinyEditorRef = useRef(null);

  //State
  const [notificationTemplateKey, setNotificationTemplateKey] = useState<string>('');
  const [notificationTemplateTitle, setNotificationTemplateTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [displayOrder, setDisplayOrder] = useState<number>(null);

  //State
  const [images, setImages] = useState<ImageType[] | null>([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [maxImageIndex, setMaxImageIndex] = useState(0);
  const [reloadFlag, setReloadFlag] = useState(false);
  const [tab, setTab] = useState(TabKey.Content);

  //Param state
  const [notificationParameter, setNotificationParameter] =
    useState<NotificationParameterType[]>(null);

  //Function
  const [isShowFullSizeImg2, setShowFullSizeImg2] = useState(false);
  const getNotificationTemplateImage = useGetNotificationTemplateImage();
  const addNotificationTemplateImage = usePostNotificationTemplateImage();
  const deleteNotificationTemplateImage = useDeleteNotificationTemplateImage();
  const editorRef = useRef(null);

  //Value
  const params = useParams<{ type: string; notificationTemplateId: string }>();
  const notificationTemplateId = Number(params.notificationTemplateId);
  const isAdd = Number(params.type) === EnumViewType.Edit && notificationTemplateId == 0;
  const isDisable = Number(params.type) == EnumViewType.View;

  useEffect(() => {
    if (!isAdd) {
      getNotificaionTemplateById(notificationTemplateId)
        .then((data) => {
          setNotificationTemplateKey(data.notificationTemplateKey);
          setNotificationTemplateTitle(data.notificationTemplateTitle);
          setValue(data.notificationTemplateValue);
          setDescription(data.description);
          setDisplayOrder(data.displayOrder);
          setNotificationParameter(data.notificationParameter);
        })
        .catch((error) => {
          isAdd
            ? null
            : addPopup({
                error: {
                  message: error.errorMessage,
                  title: 'Đã có lỗi xảy ra!',
                },
              });
        });
    }
    getNotificationTemplateImage()
      .then((data) => {
        setImages(data);
        setMaxImageIndex(data.length - 1);
      })
      .catch((error) => {
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
  }, [
    addPopup,
    isAdd,
    reloadFlag,
    getNotificaionTemplateById,
    notificationTemplateId,
    getNotificationTemplateImage,
  ]);

  //Function Content

  function handleEditorDidMount(editor: unknown) {
    editorRef.current = editor;
  }

  const onChangeParam = (value: string, index: number, type: string) => {
    if (notificationParameter) {
      const temp = notificationParameter.filter(() => true);
      if (type == 'PARAMETERNAME') {
        temp[index].parameterName = value;
      } else if (type == 'DESCRIPTION') {
        temp[index].description = value;
      } else if (type == 'DATATYPE') {
        temp[index].dataType = Number(value);
      }
      setNotificationParameter(temp);
    }
  };

  const onDeleteParam = (index: number) => {
    if (notificationParameter) {
      const temp = notificationParameter.filter(() => true);
      temp.splice(index, 1);
      setNotificationParameter(temp);
    }
  };

  const onAddParam = () => {
    if (notificationParameter) {
      const arr = notificationParameter.filter(() => true);
      const temp: NotificationParameterType = {
        parameterName: '',
        description: '',
        dataType: EnumNotificationTemplateDataType.Text,
      };
      arr.push(temp);
      setNotificationParameter(arr);
    } else {
      const arr: NotificationParameterType[] = [
        {
          parameterName: '',
          description: '',
          dataType: EnumNotificationTemplateDataType.Text,
        },
      ];
      setNotificationParameter(arr);
    }
  };

  //TODO
  const onAddNotificationTemPlate = useCallback(() => {
    const pageValue =
      tab == TabKey.SourceCode
        ? editorRef.current.getValue()
        : tinyEditorRef.current.getContent();

    const notificationTemplate: NotificationTemplateType = {
      displayOrder: displayOrder,
      notificationTemplateKey: notificationTemplateKey,
      notificationTemplateTitle: notificationTemplateTitle,
      notificationTemplateValue: pageValue,
      notificationParameter: notificationParameter,
      description: description,
    };
    postNotificationTemplate(notificationTemplate)
      .then((r) => {
        addPopup({
          txn: {
            success: true,
            summary: 'Thêm thành công!',
          },
        });
        setValue(r.notificationTemplateValue);
      })
      .catch((error) => {
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
  }, [
    tab,
    displayOrder,
    notificationTemplateKey,
    notificationTemplateTitle,
    notificationParameter,
    description,
    postNotificationTemplate,
    removePopup,
    addPopup,
  ]);

  //TODO
  const onEditNotificationTemPlate = useCallback(() => {
    const pageValue =
      tab == TabKey.SourceCode
        ? editorRef.current.getValue()
        : tinyEditorRef.current.getContent();

    const notificationTemplate: NotificationTemplateType = {
      displayOrder: displayOrder,
      notificationTemplateId: notificationTemplateId,
      notificationTemplateKey: notificationTemplateKey,
      notificationTemplateTitle: notificationTemplateTitle,
      notificationTemplateValue: pageValue,
      notificationParameter: notificationParameter,
      description: description,
    };
    putNotificationTemplate(notificationTemplate)
      .then((r) => {
        console.log('=======r=====');
        console.log(r);
        addPopup({
          txn: {
            success: true,
            summary: 'Sửa thành công',
          },
        });
        setValue(r.notificationTemplateValue);
      })
      .catch((error) => {
        addPopup({
          error: {
            message: error.errorMessage,
            title: 'Đã có lỗi xảy ra!',
          },
        });
      });
  }, [
    tab,
    displayOrder,
    notificationTemplateId,
    notificationTemplateKey,
    notificationTemplateTitle,
    notificationParameter,
    description,
    putNotificationTemplate,
    removePopup,
    addPopup,
  ]);
  //End of function

  //Function Image
  const onAddImage = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.size <= 55242880) {
        const formData = new FormData();
        formData.append('name', '');
        formData.append('file', event.target.files[0]);

        addNotificationTemplateImage(formData)
          .then(() => {
            addPopup({
              txn: {
                success: true,
                summary: 'Upload ảnh thành công!',
              },
            });
            setReloadFlag(!reloadFlag);
          })
          .catch((error) => {
            addPopup({
              error: { message: error.errorMessage, title: 'Đã có lỗi xảy ra!' },
            });
          });
        addPopup({
          txn: {
            success: true,
            summary: 'Vui lòng chờ upload ảnh!',
          },
        });
      } else {
        addPopup({
          error: { message: 'Ảnh tối đa 5MB', title: 'Đã có lỗi xảy ra!' },
        });
      }
    }
  };

  const onShowFullSize = (name: string, isShow: boolean) => {
    if (name == 'img2') setShowFullSizeImg2(isShow);
  };

  //TODO
  const onDeleteNotificationTemplateImage = () => {
    const onCancel = () => {
      console.log('cancel');
    };

    const onConfirm = () => {
      deleteNotificationTemplateImage(images[imageIndex].fileId)
        .then(() => {
          addPopup({
            txn: {
              success: true,
              summary: 'Xóa ảnh thành công!',
            },
          });
          setImageIndex(0);
          setMaxImageIndex(maxImageIndex - 1);
          setReloadFlag(!reloadFlag);
        })
        .catch((error) => {
          addPopup({
            error: {
              message: error.errorMessage,
              title: 'Đã có lỗi xảy ra!',
            },
          });
          setImageIndex(0);
          setReloadFlag(!reloadFlag);
        });
      addPopup({
        txn: {
          success: true,
          summary: 'Đợi một chút!',
        },
      });
    };
    const listToDo: EventButton[] = [
      {
        name: 'Xác nhận',
        icon: 'check',
        actionType: EnumAction.Confirm,
        action: onConfirm,
        buttonClass: 'info',
        align: 'center',
      },
      {
        name: 'Hủy',
        icon: 'clear',
        actionType: EnumAction.Cancel,
        action: onCancel,
        buttonClass: 'info',
        align: 'center',
      },
    ];
    // addPopup(
    //   {
    //     confirm: {
    //       width: '25vw',
    //       height: '20vh',
    //       question: 'Bạn có chắc muốn xóa ảnh này?',
    //       listActionButton: listToDo,
    //     },
    //   },
    //   'delete-image',
    // );
  };

  const onBackToNotificationTemplate = (e: MouseEvent) => {
    if (e.button === LEFT_MOUSE_BUTTON) {
      navigate(-1);
    }
  };

  const onChangeEditMode = (e: MouseEvent) => {
    if (e.button === LEFT_MOUSE_BUTTON) {
      navigate(
        `${BASE_SETTING_URL}/notification-template/${EnumViewType.Edit}/id/${notificationTemplateId}`,
      );
    }
  };

  const onPreview = (e: MouseEvent) => {
    if (e.button === LEFT_MOUSE_BUTTON) {
      addPopup({
        // view: {
        //   width: '85vw',
        //   height: '90vh',
        //   title: 'Xem trước',
        //   isManualRemove: true,
        //   data: (
        //     <StaticPagePreview
        //       key={uniqueId()}
        //       value={
        //         tab == TabKey.SourceCode
        //           ? editorRef?.current?.getValue()
        //           : tinyEditorRef?.current?.getContent()
        //       }
        //     />
        //   ),
        //   isContext: false,
        // },
      });
    }
  };
  //End of function

  const listEditToolButton: EventButton[] = [
    {
      name: 'Trở về',
      icon: 'arrow_back',
      actionType: EnumAction.View,
      buttonClass: 'info100 static-page-tool-btn',
      action: onBackToNotificationTemplate,
      align: 'center',
    },
    {
      name: 'Lưu',
      icon: 'add',
      actionType: isAdd ? EnumAction.Add : EnumAction.Edit,
      buttonClass: 'info static-page-tool-btn',
      action: isAdd ? onAddNotificationTemPlate : onEditNotificationTemPlate,
      align: 'center',
    },
    {
      name: 'Thêm ảnh',
      icon: 'cloud_upload',
      actionType: EnumAction.Add,
      buttonClass: 'info700 static-page-tool-btn cloud_upload-btn',
      action: onAddImage,
      align: 'center',
      file: true,
    },
    {
      name: 'Xem trước',
      icon: 'preview',
      actionType: EnumAction.View,
      buttonClass: 'info600 static-page-tool-btn cloud_upload-btn',
      action: onPreview,
      align: 'center',
    },
  ];

  const listViewToolButton: EventButton[] = [
    {
      name: 'Trở về',
      icon: 'arrow_back',
      actionType: EnumAction.View,
      buttonClass: 'info100 static-page-tool-btn',
      action: onBackToNotificationTemplate,
      align: 'center',
    },
    {
      name: 'Sửa',
      icon: 'edit',
      actionType: EnumAction.Edit,
      buttonClass: 'info100 static-page-tool-btn',
      action: onChangeEditMode,
      align: 'center',
    },
    {
      name: 'Xem trước',
      icon: 'preview',
      actionType: EnumAction.View,
      buttonClass: 'info100 static-page-tool-btn cloud_upload-btn',
      action: onPreview,
      align: 'center',
    },
  ];

  const tabs = [
    {
      title: 'Content',
      key: TabKey.Content,
      onClick: () => {
        if (tab == TabKey.SourceCode) {
          const content = editorRef.current.getValue();
          setValue(content);
        }
        setTab(TabKey.Content);
      },
    },
    {
      title: 'Source code',
      key: TabKey.SourceCode,
      onClick: () => {
        if (tab == TabKey.Content) {
          const content = tinyEditorRef.current.getContent();
          setValue(content);
        }
        setTab(TabKey.SourceCode);
      },
    },
  ];

  //Component
  const paramComponent = () => {
    return (
      <div className="email-template-detail-param-container">
        <div className="simple-param-container">
          <div className="simple-param">
            <div className="simple-param-component title">parameterName</div>
            <div className="simple-param-component title">description</div>
            <div className="simple-param-component title">dataType</div>
            <div className="email-template-btn-component title"></div>
          </div>
          {notificationParameter
            ? notificationParameter.map((value: NotificationParameterType, index: number) => {
                return (
                  <div className="simple-param">
                    <div className="simple-param-component">
                      <input
                        type="text"
                        value={value.parameterName}
                        onChange={(event) => {
                          onChangeParam(event.target.value, index, 'PARAMETERNAME');
                        }}
                      />
                    </div>
                    <div className="simple-param-component">
                      <input
                        type="text"
                        value={value.description}
                        onChange={(event) => {
                          onChangeParam(event.target.value, index, 'DESCRIPTION');
                        }}
                      />
                    </div>
                    <div className="simple-param-component">
                      <select
                        value={value.dataType}
                        onChange={(event) => {
                          onChangeParam(event.target.value, index, 'DATATYPE');
                        }}
                      >
                        <option value={EnumNotificationTemplateDataType.Text}>Text</option>
                        <option value={EnumNotificationTemplateDataType.Int}>Int</option>
                        <option value={EnumNotificationTemplateDataType.Date}>Date</option>
                        <option value={EnumNotificationTemplateDataType.PhoneNumber}>
                          PhoneNumber
                        </option>
                        <option value={EnumNotificationTemplateDataType.Email}>Email</option>
                        <option value={EnumNotificationTemplateDataType.Boolean}>
                          Boolean
                        </option>
                        <option value={EnumNotificationTemplateDataType.Percentage}>
                          Percentage
                        </option>
                        <option value={EnumNotificationTemplateDataType.BigInt}>BigInt</option>
                        <option value={EnumNotificationTemplateDataType.Decimal}>
                          Decimal
                        </option>
                        <option value={EnumNotificationTemplateDataType.Month}>Month</option>
                        <option value={EnumNotificationTemplateDataType.QuarterOfYear}>
                          QuarterOfYear
                        </option>
                        <option value={EnumNotificationTemplateDataType.Year}>Year</option>
                        <option value={EnumNotificationTemplateDataType.DateRange}>
                          DateRange
                        </option>
                      </select>
                    </div>
                    <div
                      className="email-template-btn-component title"
                      onClick={() => {
                        onDeleteParam(index);
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </div>
                  </div>
                );
              })
            : null}
          <div className="simple-param">
            <div className="email-template-add-btn" onClick={onAddParam}>
              Add param
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      {/* <ToolBar
        toolbarName={'Trang thông tin'}
        listRightButton={isDisable ? listViewToolButton : listEditToolButton}
        width={'100%'}
        height={'50px'}
        backgroundColor={'#ebe9e9'}
        isPaging={false}
      /> */}
      <div className="row">
        <div className="col-12 static-page-image">
          <div className="container-fluid">
            <div className="row justify-content-center">
              {images.map((img: ImageType, index: number) => {
                return (
                  <div
                    key={uniqueId()}
                    className={`col-1 p-0 m-1 static-page-thumb-img-child ${
                      imageIndex === index ? 'focus-img' : ''
                    }`}
                    onClick={() => {
                      setImageIndex(index);
                    }}
                  >
                    <img className="img-item" src={img.thumbUrl} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {paramComponent()}
        <div className="col-12 add-static-page-view">
          <div className="row">
            <div className="col-9 content">
              <div className="value-content">
                <div className="row content-row">
                  <div className="col-3">
                    <div className="add-static-page-child">
                      <span className="mr-2">
                        Key:<span style={{ color: 'red' }}>*</span>
                      </span>
                      <Input
                        disabled={isDisable}
                        value={notificationTemplateKey}
                        onChange={setNotificationTemplateKey}
                      />
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="add-static-page-child">
                      <span className="mr-2">
                        Tiêu đề:<span style={{ color: 'red' }}>*</span>
                      </span>
                      <Input
                        disabled={isDisable}
                        value={notificationTemplateTitle}
                        onChange={setNotificationTemplateTitle}
                      />
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="add-static-page-child">
                      <span className="mr-2">
                        Thứ tự:<span style={{ color: 'red' }}>*</span>
                      </span>
                      <Input
                        disabled={isDisable}
                        value={displayOrder}
                        onChange={setDisplayOrder}
                      />
                    </div>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-12">
                    <div className="add-static-page-child">
                      <span className="mr-2">Mô tả:</span>
                      <Input
                        disabled={isDisable}
                        value={description}
                        onChange={setDescription}
                      />
                    </div>
                  </div>
                </div>
                <div className="row content-row">
                  <div className="col-12">
                    <div className="add-static-page-child">
                      <span className="mr-2">
                        Nội dung:<span style={{ color: 'red' }}>*</span>
                      </span>

                      <Tabs activeTab={tab} tabs={tabs} />
                      <div className="tabs-content">
                        {tab == TabKey.SourceCode ? (
                          <MonacoEditor
                            height="90vh"
                            defaultLanguage="html"
                            value={value}
                            onMount={handleEditorDidMount}
                            options={{
                              readOnly: isDisable,
                            }}
                          />
                        ) : (
                          <Editor
                            disabled={isDisable}
                            apiKey={'9rjkn0nooxthjws4ylk4s6ogwe2x1ll74eozkdq1or2maa59'}
                            onInit={(evt, editor) => (tinyEditorRef.current = editor)}
                            initialValue={value}
                            init={{
                              height: '90vh',
                              menubar: true,
                              plugins: [
                                'link image code textcolor charmap preview anchor searchreplace visualblocks fullscreen insertdatetime media table code wordcount help align',
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount',
                              ],
                              toolbar:
                                'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'myButton | undo | redo | link | image | code | forecolor| backcolor |charmap |align |print | preview | anchor | view |searchreplace |visualblocks |fullscreen| insertdatetime |media |table |paste |help |wordcount' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                              content_style:
                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 content">
              <div className="static-page-fullsize-img">
                {images.length > 0 ? (
                  <div
                    className="btn-nav-static-page-image back"
                    onClick={() => {
                      setImageIndex(imageIndex - 1 >= 0 ? imageIndex - 1 : maxImageIndex);
                    }}
                  >
                    <span className="material-icons">arrow_back_ios_new</span>
                  </div>
                ) : null}
                <div
                  className={`static-page-img-child center ${
                    isShowFullSizeImg2 ? 'full-size' : ''
                  }`}
                >
                  {images.length > 0 ? (
                    <div
                      className="btn-delete-static-page-image"
                      title="Xóa"
                      onClick={() => {
                        onDeleteNotificationTemplateImage();
                      }}
                    >
                      <span className="material-icons">delete</span>
                    </div>
                  ) : null}
                  {images.length > 0 ? (
                    <div>
                      <div
                        className="btn-zoom-out-static-page-image"
                        title="Thu nhỏ"
                        onClick={() => {
                          onShowFullSize('img2', false);
                        }}
                      >
                        <span className="material-icons">close</span>
                      </div>
                      <img
                        onClick={() => {
                          onShowFullSize('img2', true);
                        }}
                        src={images[imageIndex].fileUrl}
                      />
                    </div>
                  ) : null}
                </div>
                {images.length > 0 ? (
                  <div
                    className="btn-nav-static-page-image next"
                    onClick={() => {
                      setImageIndex(imageIndex + 1 > maxImageIndex ? 0 : imageIndex + 1);
                    }}
                  >
                    <span className="material-icons">arrow_forward_ios</span>
                  </div>
                ) : null}
              </div>
              <p style={{ textAlign: 'center' }}>Link ảnh:</p>
              <p style={{ textAlign: 'center' }}>{images[imageIndex]?.fileUrl}</p>
              <p style={{ textAlign: 'center' }}>Link thumb:</p>
              <p style={{ textAlign: 'center' }}>{images[imageIndex]?.thumbUrl}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationTemplateDetail;
